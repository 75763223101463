import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Rating from 'react-rating';

const FillForm = (props) => {
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ratings, setRatings] = useState({});
    const API_URL = process.env.REACT_APP_API_URL
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [time, setTime] = useState('');

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/Custom/formDetails/${props.studentId}/${props.formTypeId}`)
            .then(response => {
                const data = response.data; // API'den gelen veri

                setQuestions(data.questionsAndAnswers); // Soruları ve yanıtları direkt olarak kaydet

                // Yanıtları kullanarak initialRatings'i ayarla
                // Eğer bir soru için yanıt yoksa varsayılan değeri kullan
                const initialRatings = data.questionsAndAnswers.reduce((acc, item) => {
                    const rating = item.answerText ? parseInt(item.answerText) : item.questionOptional ? -1 : (props.formTypeId == 1 || props.formTypeId == 5 || props.formTypeId == 7 ? 4 : 8);
                    return {
                        ...acc,
                        [item.questionId]: rating
                    };
                }, {});
                console.log(initialRatings);
                setRatings(initialRatings);
                setGender(data.gender);
                setAge(data.age);
                setTime(data.name);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the form details!', error);
                setIsLoading(false);
            });
    }, [props.studentId, props.formTypeId]);


    if (isLoading) {
        return <p>Loading questions...</p>;
    }

    const handleRatingChange = (questionId, value) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [questionId]: value
        }));
    };

    const genderOptions = [
        { value: 'Erkek', label: 'Erkek' },
        { value: 'Kadın', label: 'Kadın' },
    ];

    const timeOptions = [
        { value: 'Sabah', label: '13:00 Öncesi' },
        { value: 'Aksam', label: '13:00 Sonrası' },
    ];

    const ageOptions = [
        { value: '25-', label: '25-' },
        { value: '26-35', label: '26-35' },
        { value: '36-45', label: '36-45' },
        { value: '46-55', label: '46-55' },
        { value: '55+', label: '55+' },
        // ... Diğer yaş seçeneklerini ekleyin
    ];

    // ... (handleRatingChange ve diğer fonksiyonlar)

    const handleGenderChange = selectedOption => {
        setGender(selectedOption.value);
    };

    const handleAgeChange = selectedOption => {
        setAge(selectedOption.value);
    };

    const handleTimeChange = selectedOption => {
        setTime(selectedOption.value);
    };
    const handleOptionalChange = (questionId) => {
        // Eğer opsiyonel ise, rating'i özel bir değere (-1 gibi) ayarlayın
        setRatings(prevRatings => ({
            ...prevRatings,
            [questionId]: prevRatings[questionId] === -1 ? 0 : -1  // Eğer zaten -1 ise 0 yap, değilse -1 yap
        }));
    };
    const handleSubmit = () => {
        const answerDtos = questions.map(question => ({
            questionId: question.questionId,
            response: ratings[question.questionId].toString()
        }));

        axios.post(`${API_URL}/Custom/submitform`, {
            answerDtos,
            studentId: props.studentId, // Seçilen öğrencinin ID'si
            formTypeId: props.formTypeId, // Seçilen form türünün ID'si
            gender,
            age,
            name: time
        })
            .then(response => {
                console.log('Form submitted:', response.data);
                props.onFormSubmit(props.formTypeId);
                // Başarılı gönderim sonrası işlemler (mesaj gösterme, state güncelleme vs.)
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                // Hata yönetimi
            });
    };
    return (
        <div className="fill-form">
            {/* Gender Seçimi */}
            <Select
                options={genderOptions}
                onChange={handleGenderChange}
                value={genderOptions.find(option => option.value === gender)}
                placeholder="Hastanın Cinsiyeti"
            />

            {/* Age Seçimi */}
            <Select
                options={ageOptions}
                onChange={handleAgeChange}
                value={ageOptions.find(option => option.value === age)}
                placeholder="Hastanın Yaşı"
            />
            {/* Time Seçimi */}
            <Select
                options={timeOptions}
                onChange={handleTimeChange}
                value={timeOptions.find(option => option.value === time)}
                placeholder="Uygulama Zamanı"
            />
            {
                questions.length > 0 ? (
                    questions.map(question => (
                        <div key={question.questionId} className="question">
                            <p>{question.questionText}</p>
                            {question.questionOptional && (
                                <span
                                    className={`optional-indicator ${ratings[question.questionId] === -1 ? 'selected' : ''}`}
                                    onClick={() => handleOptionalChange(question.questionId)}
                                    style={{
                                        display: "inline-block",
                                        borderRadius: "50%",
                                        border: "5px double white",
                                        width: "30px",
                                        height: "30px",
                                        cursor:"pointer",
                                        backgroundColor: `${ratings[question.questionId] === -1 ? 'orange' : '#ffa50052'}`
                                        }}
                                >
                                    
                                </span>
                            )}
                            <Rating
                                stop={props.formTypeId == 1 || props.formTypeId == 5 || props.formTypeId == 7 ? 5 : 9}
                                initialRating={ratings[question.questionId] === -1 ? 0 : ratings[question.questionId]}
                                onChange={(value) => handleRatingChange(question.questionId, value)}
                                
                            />
                        </div>
                    ))
                ) : (
                    <p>No questions available for this form.</p>
                )
            }

            <button onClick={handleSubmit} className="btn btn-success">Kaydet</button>
        </div>
    );
};

export default FillForm;
