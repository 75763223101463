/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
          />
      <AsideMenuItem
              to='/modellist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ModelList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/methodlist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.MethodList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/rolemethodlist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.RoleMethodList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/rolemodellist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.RoleModelList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/menulist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.MenuList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/menurole'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.MenuRole' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/useraction'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.UserAction' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/culture'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Culture' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/translation'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Translation' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/applicationuser'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationUser' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/applicationrole'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationRole' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/applicationuserrole'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationUserRole' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/applicationuserclaim'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationUserClaim' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/applicationroleclaim'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationRoleClaim' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/tenant'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Tenant' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/page'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Page' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/menuitem'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.MenuItem' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/slider'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Slider' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/contactform'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ContactForm' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/settings'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Settings' })}
              fontIcon='bi-layers'
          />

    </>
  )
}
