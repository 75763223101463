import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardPage} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const ModelListPage = lazy(() => import('../pages/modellist/Page'))
const MethodListPage = lazy(() => import('../pages/methodlist/Page'))
const RoleMethodListPage = lazy(() => import('../pages/rolemethodlist/Page'))
const RoleModelListPage = lazy(() => import('../pages/rolemodellist/Page'))
const MenuListPage = lazy(() => import('../pages/menulist/Page'))
const MenuRolePage = lazy(() => import('../pages/menurole/Page'))
const UserActionPage = lazy(() => import('../pages/useraction/Page'))
const CulturePage = lazy(() => import('../pages/culture/Page'))
const TranslationPage = lazy(() => import('../pages/translation/Page'))
const ApplicationUserPage = lazy(() => import('../pages/applicationuser/Page'))
const ApplicationRolePage = lazy(() => import('../pages/applicationrole/Page'))
const ApplicationUserRolePage = lazy(() => import('../pages/applicationuserrole/Page'))
const ApplicationUserClaimPage = lazy(() => import('../pages/applicationuserclaim/Page'))
const ApplicationRoleClaimPage = lazy(() => import('../pages/applicationroleclaim/Page'))
const TenantPage = lazy(() => import('../pages/tenant/Page'))
const StudentPage = lazy(() => import('../pages/student/Page'))
const QuestionPage = lazy(() => import('../pages/question/Page'))
const AnswerPage = lazy(() => import('../pages/answer/Page'))
const PerformanceEvaluationFormPage = lazy(() => import('../pages/performanceevaluationform/Page'))
const FormTypePage = lazy(() => import('../pages/formtype/Page'))

  return (
      <Routes>

        <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        
        <Route path='dashboard' element={<DashboardPage />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
              <Route
                        path='modellist/*'
                        element={
                        <SuspensedView>
                            <ModelListPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='methodlist/*'
                        element={
                        <SuspensedView>
                            <MethodListPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='rolemethodlist/*'
                        element={
                        <SuspensedView>
                            <RoleMethodListPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='rolemodellist/*'
                        element={
                        <SuspensedView>
                            <RoleModelListPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='menulist/*'
                        element={
                        <SuspensedView>
                            <MenuListPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='menurole/*'
                        element={
                        <SuspensedView>
                            <MenuRolePage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='useraction/*'
                        element={
                        <SuspensedView>
                            <UserActionPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='culture/*'
                        element={
                        <SuspensedView>
                            <CulturePage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='translation/*'
                        element={
                        <SuspensedView>
                            <TranslationPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='applicationuser/*'
                        element={
                        <SuspensedView>
                            <ApplicationUserPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='applicationrole/*'
                        element={
                        <SuspensedView>
                            <ApplicationRolePage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='applicationuserrole/*'
                        element={
                        <SuspensedView>
                            <ApplicationUserRolePage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='applicationuserclaim/*'
                        element={
                        <SuspensedView>
                            <ApplicationUserClaimPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='applicationroleclaim/*'
                        element={
                        <SuspensedView>
                            <ApplicationRoleClaimPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='tenant/*'
                        element={
                        <SuspensedView>
                            <TenantPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='student/*'
                        element={
                        <SuspensedView>
                            <StudentPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='question/*'
                        element={
                        <SuspensedView>
                            <QuestionPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='answer/*'
                        element={
                        <SuspensedView>
                            <AnswerPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='performanceevaluationform/*'
                        element={
                        <SuspensedView>
                            <PerformanceEvaluationFormPage />
                        </SuspensedView>
                        }
                    />
<Route
                        path='formtype/*'
                        element={
                        <SuspensedView>
                            <FormTypePage />
                        </SuspensedView>
                        }
                    />

        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
